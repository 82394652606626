import React from "react";
import Helmet from "react-helmet";

import "../assets/scss/main.scss";
import "../assets/scss/appstore.scss";

import logo from '../assets/images/dbayconnect/logo.png'

class AppPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
          loading: 'is-loading'
        }
      }
  
      componentDidMount () {
        this.timeoutId = setTimeout(() => {
            this.setState({loading: ''});
        }, 100);
      }
  
      componentWillUnmount () {
        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
        }
    }
          
    render() {
        const siteTitle = "愉景通";

        return (
        <div className={`body ${this.state.loading}`}>

            <Helmet title={siteTitle} />

            <section id="footer">
                <div className="inner">
                    <span><img src={logo} alt="" width="120px" height="120px"/></span>
                    <h1><strong>愉景通</strong></h1>
                    <p>愉景灣居民必備App, 有齊愉景灣居民常用電話, 交通工具時間表等有用資料.</p>
                </div>

                <p></p>
                
                <ul className="icons">
                    <li>
                        <a className="icon appStoreButton" href='https://play.google.com/store/apps/details?id=com.sumlook.dbayconnect'><img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/></a>
                        <a className="icon appStoreButton" href='https://apps.apple.com/us/app/dbay-connect/id1538602293'><img alt='Get it on Apple App Store' src="/images/apple-app-store.png"/></a>
                        <a className="icon appStoreButton" href='https://appgallery.cloud.huawei.com/ag/n/app/C103191077?channelId=sumlook.com&referrer=www.sumlook.com&id=688804b7bcd54673b8166079d07226c8&s=634A527E770E1649ECD01CB32780E3C07B302167165926A3DFAB8CB2136F5CEC&detailType=0&v='><img alt='Get it on Huawei AppGallery' src="/images/huawei-appgallery.png"/></a>
                    </li> 
                </ul>
               
 
                <ul className="copyright">
                    <li>© Sumlook Production</li>
                </ul>

            </section>

        </div>
        );
    }
}

export default AppPage;